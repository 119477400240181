<template> 
    <div class="page-content"> 
        <div class="grid"> 
            <div class="row no-gutters page-list-header"> 
                <div class="col l-6 m-6 c-12"> 
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách nhóm</span></h4> 
                </div> 
                <div class="col l-6 m-6 c-12"> 
                    <div class="page-list-action">  
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary"> 
                            <md-icon>cached</md-icon> 
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button> 
                    </div> 
                </div> 
            </div> 
            <div class="page-list-body"> 
                <div class="table-content"> 
                    <table class="data-table"> 
                        <thead> 
                            <tr> 
                                <th style="width:100px;">#</th> 
                                <th class="left" style="width:25%;">Phòng/ban</th> 
                                <th class="left" style="width:30%;">Tên nhóm</th> 
                                <th class="left" style="width:25%;">Leader</th> 
                                <th style="width:15%;">Hành động</th> 
                            </tr> 
                         </thead> 
                        <tbody v-if="loadding == true" style="height: 150px;"> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr> 
                        </tbody> 
                        <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody> 
                        <tbody v-if="loadding == false && data.length > 0"> 
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                <td class="center">{{index + 1}}</td> 
                                <td class="left">{{item.dept.name}}</td> 
                                <td class="left">{{item.teamName}}</td> 
                                <td class="left">{{item.lead.fullName}}</td> 
                                <td class="action"> 
                                    <router-link :to="'/team/crud/' + item.id"> 
                                        <md-button class="md-fab md-mini md-second"> 
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button> 
                                    </router-link> 
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain"> 
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button> 
                                </td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div> 
            </div> 
        </div> 
    </div> 
</template>
<script> 
    import teamService from '../../../api/teamService'; 
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex'; 

    export default { 
        metaInfo: { 
            title: 'Danh sách nhóm' 
        }, 
        data() { 
            return { 
               loadding: false, 
               data: [], 
               id: 0,
               deptId: 0
            } 
        }, 
        created(){ 
            this.getData(); 
        }, 
        methods: { 
            ...mapActions('common', ['setLoading']), 

            refresh(){ 
                this.getData(); 
            }, 

            getData(){ 
               this.loadding = true; 
               teamService.getAll(this.deptId).then((response) => { 
                   if(response.statusCode == 200){ 
                       this.data = response.data;  
                   } 
                   else{ 
                       messageBox.showWarning(response.data); 
                   } 
                }).finally(() => { this.loadding = false; }); 
            }, 

            confirmDelete(id){ 
               this.$modal.show('dialog', { 
               title: 'Thông báo', 
               text: 'Bạn có chắc muốn xóa không?', 
               buttons: [ 
                       { 
                           title: 'Hủy', 
                           handler: () => { 
                               this.$modal.hide('dialog') 
                           } 
                       }, 
                       { 
                           title: 'Xóa', 
                           handler: () => { 
                               this.del(id) 
                           } 
                       } 
               ] 
               }) 
            }, 

            del(id){ 
               this.$modal.hide('dialog'); 
               this.setLoading(true); 
               teamService.delete(id).then((response) => { 
                   if(response.statusCode == 200){ 
                       messageBox.showMessage("Xóa thành công"); 
                       this.refresh(); 
                   } 
                   else{ 
                       messageBox.showWarning(response.data); 
                   } 
                }).finally(() => { this.setLoading(false); }); 
            }, 

            add(){
                this.$router.push('/team/crud');
            },
        }, 
        watch: { 
            deptId: function () { 
                this.getAll(); 
            }
        } 
    } 

</script> 
